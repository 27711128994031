import type BookingData from '~/types/booking/BookingData'
import type BookingSlot from '~/types/booking/BookingSlot'

export const useBookingStore = defineStore('booking', () => {
  const booking = ref<BookingData | null>(null)
  const loadingBookingData = ref(false)

  const router = useRouter()

  const fetchBooking = async (slug: string) => {
    try {
      loadingBookingData.value = true
      const { data, execute, error } = await useApi<BookingData>(`/booking/slug/${slug}`, { immediate: false })
      await execute()
      if (isDefined(data)) {
        booking.value = data.value!
      }

      if (isDefined(error)) {
        if (error.value?.statusCode === 404) {
          router.push({ name: 'all' })
        }
      }
    }
    finally {
      loadingBookingData.value = false
    }
  }

  async function fetchPreviewBooking(eventId: string) {
    try {
      loadingBookingData.value = true
      const { data, execute, error } = await useApi<BookingData>(`/booking/events/${eventId}/preview`, { immediate: false })
      await execute()
      if (isDefined(data)) {
        booking.value = data.value!
      }

      if (isDefined(error)) {
        if (error.value?.statusCode === 404) {
          router.push({ name: 'all' })
        }
      }
    }
    finally {
      loadingBookingData.value = false
    }
  }

  const selectedBookingSlot = ref<BookingSlot | null>(null)
  const bookingConfirmed = ref(false)
  async function bookSlot(slug: string) {
    const { execute } = useApi<BookingSlot>(`/booking/slug/${slug}/book`, {
      method: 'POST',
      immediate: false,
      body: {
        slot_id: selectedBookingSlot.value!.id,
      },
    })

    await execute()
    await fetchBooking(slug)
    bookingConfirmed.value = true
  }

  async function unbook(slug: string) {
    const { execute } = useApi<BookingSlot>(`/booking/slug/${slug}/unbook`, {
      method: 'POST',
      immediate: false,
      body: {
        slot_id: booking.value?.booked_slot.id,
      },
    })

    await execute()
    bookingConfirmed.value = false
    selectedBookingSlot.value = null
    fetchBooking(slug)
  }

  return {
    booking,
    fetchBooking,
    selectedBookingSlot,
    fetchPreviewBooking,
    bookingConfirmed,
    bookSlot,
    unbook,
  }
})
